import { useTour } from '@reactour/tour'
import { useEffect } from 'react'

import { TourNames, TOUR_QUERY_PARAM } from 'modules/guiders/constants'
import { useStartTour } from 'modules/guiders/hooks/useStartTour'
import { replaceState } from 'modules/history'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { elementReady } from 'utils/dom'
import { localStore } from 'utils/storage'
import { getExistingQueryParams } from 'utils/url'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { DOC_EDITOR_TOUR_STEPS } from './DocEditorTourSteps'

export const useAutoStartDocEditorTour = () => {
  const analytics = useAnalytics()
  const { startTour } = useStartTour(
    TourNames.DOC_EDITOR,
    DOC_EDITOR_TOUR_STEPS
  )
  const { setIsOpen } = useTour()

  useEffect(() => {
    // The tour should only run when someone has been enqueued to see it
    if (getExistingQueryParams()[TOUR_QUERY_PARAM] === 'true') {
      // Let's make sure the editor is ready before starting the tour.
      elementReady(DOC_EDITOR_TOUR_STEPS[1].selector as string).then(() => {
        startTour()
        analytics?.track(SegmentEvents.DOC_EDITOR_TOUR_STARTED, {
          source: 'queryParam',
        })
        // Strip the query parameter
        replaceState({
          query: {
            ...getExistingQueryParams(),
            [TOUR_QUERY_PARAM]: undefined,
          },
          emitChange: false,
        })
      })
    }
  }, [analytics, startTour])

  // how do we close the tour
  useEffect(() => {
    // If the tour is open, close it if the component that called this hook unmounts.
    return () => {
      setIsOpen(false)
    }
  }, [setIsOpen])
}

export const useImperativeDocEditorTour = () => {
  const { startTour } = useStartTour(
    TourNames.DOC_EDITOR,
    DOC_EDITOR_TOUR_STEPS
  )
  return { startTour }
}
