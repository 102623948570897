import { Box, Text } from '@chakra-ui/react'
import React from 'react'

export type ShortcutConfig = {
  description: string
  shortcut: string | React.ReactElement | Array<string | React.ReactElement>
}

const ShortcutWithSupportText = ({
  prefix = '',
  support = '',
  suffix = '',
}) => {
  // Lets you include supporting text in grey that doesn't look like part of the shortcut itself
  return (
    <Text>
      {prefix}
      <Text color="gray.400" as="span">
        {support}
      </Text>
      {suffix}
    </Text>
  )
}

export const DOC_MODE_SHORTCUTS: Array<ShortcutConfig> = [
  {
    description: 'Open slash menu',
    shortcut: '/',
  },
  {
    description: 'Open/close cards',
    shortcut: 'Mod+Shift+O',
  },
  {
    description: 'Split card',
    shortcut: '***',
  },
  {
    description: 'Print/export PDF',
    shortcut: 'Mod+P',
  },
  {
    description: 'Enter present mode',
    shortcut: 'Mod+Enter',
  },
  {
    description: 'Jump to',
    shortcut: 'Mod+K',
  },
  {
    description: 'Mention a person',
    shortcut: <ShortcutWithSupportText prefix="@" support="name" />,
  },
  {
    description: 'Link to a card',
    shortcut: <ShortcutWithSupportText prefix="@" support="cardTitle" />,
  },
]

export const TEXT_FORMATTING_SHORTCUTS: Array<ShortcutConfig> = [
  {
    description: 'Title',
    shortcut: '/title',
  },
  {
    description: 'Heading 1',
    shortcut: ['#', 'Mod+Alt+1'],
  },
  {
    description: 'Heading 2',
    shortcut: ['##', 'Mod+Alt+2'],
  },
  {
    description: 'Heading 3',
    shortcut: ['###', 'Mod+Alt+3'],
  },
  {
    description: 'Heading 4',
    shortcut: ['####', 'Mod+Alt+4'],
  },

  { description: 'Link', shortcut: 'Mod+K' },

  {
    description: 'Emoji 😍',
    shortcut: (
      <ShortcutWithSupportText prefix=":" support="heart_eyes" suffix=":" />
    ),
  },

  {
    description: 'Bold',
    shortcut: [
      <ShortcutWithSupportText key="0" prefix="*" support="bold" suffix="*" />,
      'Mod+B',
    ],
  },

  {
    description: 'Italic',
    shortcut: [
      <ShortcutWithSupportText
        key="0"
        prefix="_"
        support="italic"
        suffix="_"
      />,
      'Mod+I',
    ],
  },

  { description: 'Underline', shortcut: 'Mod+U' },

  { description: 'Strikethrough', shortcut: 'Mod+Shift+X' },

  { description: 'Left align', shortcut: 'Mod+Shift+L' },

  { description: 'Center align', shortcut: 'Mod+Shift+E' },

  { description: 'Right align', shortcut: 'Mod+Shift+R' },

  {
    description: 'Code snippet',
    shortcut: [
      <ShortcutWithSupportText key="0" prefix="`" support="code" suffix="`" />,
      'Mod+E',
    ],
  },

  {
    description: 'Math equation',
    shortcut: [
      <ShortcutWithSupportText key="0" prefix="$" support="x^2" suffix="$ " />,
      '$$ ',
    ],
  },

  { description: 'Highlight text', shortcut: 'Mod+Shift+H' },

  { description: 'Numbered list', shortcut: ['1. ', 'Mod+/', 'Mod+Shift+7'] },

  { description: 'Bulleted list', shortcut: ['- ', 'Mod+.', 'Mod+Shift+8'] },

  { description: 'Todo list', shortcut: ['[] ', 'Mod+,', 'Mod+Shift+9'] },

  {
    description: 'Footnote',
    shortcut: [
      <ShortcutWithSupportText key="0" prefix="^" support="note" suffix="^" />,
      'Mod+Alt+F',
    ],
  },
]

export const PRESENT_MODE_SHORTCUTS: Array<ShortcutConfig> = [
  { description: 'Previous/next card', shortcut: ['←', '→'] },

  { description: 'Scroll up/down card', shortcut: ['↑', '↓'] },

  { description: 'Open/close card', shortcut: 'Enter' },

  { description: 'Exit present mode', shortcut: 'Esc' },

  {
    description: 'Open/close all cards',
    shortcut: 'Mod+Shift+O',
  },

  { description: 'Spotlight on/off', shortcut: 'S' },

  { description: 'Restart presentation', shortcut: 'R' },

  {
    description: 'Double click to edit',
    shortcut: (
      <Box p={0} key="cursor">
        {/* Todo: use an SVG loader to add this - https://stackoverflow.com/questions/55175445/cant-import-svg-into-next-js */}
        <svg
          className="coachbarCursor"
          height="1em"
          viewBox="0 0 298 380"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="cursorBlinkEffect"
            d="M151.8 0.60199C145.612 0.60199 140.601 5.61759 140.601 11.801V79C140.601 85.1875 145.617 90.199 151.8 90.199C157.987 90.199 162.999 85.1834 162.999 79V11.801C162.999 5.61349 157.983 0.60199 151.8 0.60199ZM52.749 41.551C49.8818 41.551 47.0615 42.6877 44.874 44.8752C40.5029 49.2502 40.5029 56.4262 44.874 60.8012L84.425 100.352C88.8 104.727 95.976 104.723 100.351 100.352C104.726 95.9772 104.722 88.8012 100.351 84.4262L60.8 44.8752C58.6125 42.6877 55.6162 41.551 52.749 41.551ZM250.849 41.551C247.982 41.551 244.986 42.6877 242.798 44.8752L203.247 84.4262C198.876 88.8012 198.872 95.9772 203.247 100.352C207.622 104.723 214.798 104.727 219.173 100.352L258.724 60.8012C263.095 56.4262 263.095 49.2502 258.724 44.8752C256.537 42.6877 253.716 41.551 250.849 41.551ZM11.789 140.602C5.6015 140.602 0.589996 145.618 0.589996 151.801C0.589996 157.988 5.6056 163 11.789 163H78.988C85.1755 163 90.187 157.984 90.187 151.801C90.187 145.613 85.1714 140.602 78.988 140.602H11.789ZM92.465 199.926C89.5978 199.926 86.6017 201.063 84.4142 203.25L44.8632 242.801C40.4921 247.176 40.4921 254.352 44.8632 258.727C49.2382 263.102 56.4142 263.102 60.7892 258.727L100.34 219.176C104.711 214.801 104.715 207.625 100.34 203.25C98.1527 201.063 95.3322 199.926 92.465 199.926Z"
            fill="black"
          />
          <path
            d="M289.317 291.063H237.601L264.819 357.2C266.715 361.785 264.548 366.926 260.215 368.872L236.247 379.292C231.779 381.237 226.768 379.014 224.873 374.568L199.009 311.766L156.761 355.116C151.13 360.892 142 356.439 142 348.864V139.899C142 131.923 151.711 128.035 156.76 133.647L295.411 275.918C301.003 281.355 296.877 291.063 289.317 291.063V291.063Z"
            fill="black"
          />
        </svg>
      </Box>
    ),
  },
]
