import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { format, parseISO } from 'date-fns'
import { memo } from 'react'

import { Theme } from '../types'
import { DeleteThemeModal } from './DeleteThemeModal'

type ThemePreviewMenuProps = {
  theme: Theme
  openThemeEditorWithThisTheme: () => void
  onDuplicate: () => void
  onDeleteThemeModalOpen: () => void
  isDeleteThemeModalOpen: boolean
  onDeleteThemeModalClose: () => void
}
const ThemePreviewMenuComponent = ({
  theme,
  openThemeEditorWithThisTheme,
  onDuplicate,
  onDeleteThemeModalOpen,
  isDeleteThemeModalOpen,
  onDeleteThemeModalClose,
}: ThemePreviewMenuProps) => {
  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        isRound
        size="xs"
        variant="ghost"
        colorScheme="blackAlpha"
        icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
        onClick={(ev) => ev.stopPropagation()} // Prevents clicking the ... from changing the theme
      >
        Actions
      </MenuButton>

      <Portal>
        <MenuList
          zIndex="modal" // Necessary when this component is rendered inside a drawer
          onClick={(ev) => ev.stopPropagation()} // Prevents clicking a menu item from changing the theme
        >
          <Box px={4} py={0} maxWidth="300px">
            <Text noOfLines={3}>{theme.name}</Text>
            {theme.createdTime && (
              <Text fontSize="sm" color="gray.500" noOfLines={1}>
                Created {format(parseISO(theme.createdTime), 'PPP')}
              </Text>
            )}
            {theme.createdBy && (
              <GammaTooltip label={theme.createdBy.email}>
                <Text
                  display="inline-block"
                  fontSize="sm"
                  color="gray.500"
                  noOfLines={1}
                >
                  by {theme.createdBy.displayName}
                </Text>
              </GammaTooltip>
            )}
          </Box>
          <MenuDivider />
          <MenuItem
            icon={<FontAwesomeIcon icon={regular('palette')} fixedWidth />}
            onClick={openThemeEditorWithThisTheme}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<FontAwesomeIcon icon={regular('clone')} fixedWidth />}
            onClick={onDuplicate}
          >
            Duplicate
          </MenuItem>
          <MenuDivider />
          <MenuItem
            color="red.500"
            icon={<FontAwesomeIcon icon={regular('trash')} fixedWidth />}
            onClick={onDeleteThemeModalOpen}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Portal>
      {isDeleteThemeModalOpen && (
        <Portal>
          <DeleteThemeModal
            theme={theme}
            isOpen={true}
            onClose={onDeleteThemeModalClose}
          />
        </Portal>
      )}
    </Menu>
  )
}

export const ThemePreviewMenu = memo(ThemePreviewMenuComponent)
