import {
  Box,
  Circle,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useHealthCheck } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useImperativeDocEditorTour } from 'modules/guiders'
import { FEEDBACK_LINK } from 'modules/help/constants'
import { launchIntercomUI } from 'modules/intercom'
import { setIsIntercomOpen } from 'modules/intercom/reducer'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import { HelpWidgetWrapper } from '../HelpWidgetWrapper'
import { KeyboardShortcutsPopover } from '../KeyboardShortcutsPopover'

const BORDERLESS_FRAME_INTERVAL = 1000

const WHATS_NEW_URL =
  'https://gamma.app/public/Change-Log-awopg3inl2eo3b3?mode=doc#card-7l4mxahmsc10wrp'

const FAQ_URL =
  'https://gamma.app/public/Frequently-Asked-Questions-FAQ-o2he6ual8noal7u?mode=doc'
export const DefaultHelpWidget = memo(
  ({
    showShortcuts = true,
    showTourReplay = false,
  }: {
    showShortcuts?: boolean
    showTourReplay?: boolean
  }) => {
    const { startTour } = useImperativeDocEditorTour()
    const dispatch = useDispatch()
    const { isConnected } = useHealthCheck()
    const isIntercomEnabled = useFeatureFlag('intercom')
    const {
      isOpen: isKeyboardShortcutsPopoverOpen,
      onOpen: onKeyboardShortcutsPopoverOpen,
      onClose: onKeyboardShortcutsPopoverClose,
    } = useDisclosure()
    // Store the unread state in localStorage so it can survive a page refresh
    const [hasUnread, setHasUnread] = useLocalStorage(
      'hasUnreadIntercom',
      false
    )
    const analytics = useAnalytics()
    const intercom = window.Intercom

    const handleContactUsClick = useCallback(() => {
      launchIntercomUI()
      setHasUnread(false)
      dispatch(setIsIntercomOpen({ isOpen: true }))
    }, [dispatch, setHasUnread])

    useEffect(() => {
      if (!intercom) return

      intercom('onHide', () => {
        dispatch(setIsIntercomOpen({ isOpen: false }))
      })
      const intervalId = setInterval(() => {
        // This is a super-hack to determine if any messages have come in
        // since our last interaction with the help widget.
        if (document.querySelector('iframe.intercom-borderless-frame')) {
          // We'll clear this when opening the widget!
          setHasUnread(true)
        }
      }, BORDERLESS_FRAME_INTERVAL)

      return () => {
        intercom('hide')
        clearInterval(intervalId)
      }
    }, [dispatch, intercom, setHasUnread])

    return (
      <HelpWidgetWrapper>
        <Menu>
          <MenuButton
            data-guider-highlight="default-help-widget-activator"
            as={IconButton}
            isRound
            position="relative"
            size="md"
            aria-label="Help"
            icon={
              hasUnread ? (
                <>
                  <Circle
                    position="absolute"
                    size={2.5}
                    bg="red.400"
                    top={0}
                    right={0}
                  />
                  <FontAwesomeIcon
                    icon={regular('bell')}
                    className="fa-beat-fade"
                  />
                </>
              ) : (
                <FontAwesomeIcon icon={regular('question')} />
              )
            }
          />
          <MenuList>
            <MenuGroup>
              {showShortcuts && (
                <MenuItem
                  onClick={onKeyboardShortcutsPopoverOpen}
                  icon={
                    <FontAwesomeIcon fixedWidth icon={regular('keyboard')} />
                  }
                >
                  Shortcuts
                </MenuItem>
              )}
              <MenuItem
                as={Link}
                _hover={{ textDecoration: 'none', shadow: 'none' }}
                href={WHATS_NEW_URL}
                target="_blank"
                icon={<FontAwesomeIcon fixedWidth icon={regular('sparkles')} />}
              >
                What's new
              </MenuItem>
              <MenuItem
                as={Link}
                _hover={{ textDecoration: 'none', shadow: 'none' }}
                href={FAQ_URL}
                target="_blank"
                icon={
                  <FontAwesomeIcon fixedWidth icon={regular('seal-question')} />
                }
              >
                FAQ
              </MenuItem>
              {showTourReplay && (
                <MenuItem
                  as={Link}
                  _hover={{ textDecoration: 'none', shadow: 'none' }}
                  onClick={() => {
                    analytics?.track(SegmentEvents.DOC_EDITOR_TOUR_STARTED, {
                      source: 'help-widget',
                    })
                    startTour(0)
                  }}
                  icon={<FontAwesomeIcon fixedWidth icon={regular('map')} />}
                  data-guider-highlight="replay-intro-tour"
                >
                  Take the editor tour
                </MenuItem>
              )}
            </MenuGroup>
            <MenuDivider />
            <MenuGroup>
              {isIntercomEnabled && (
                <GammaTooltip
                  label={
                    isConnected ? null : (
                      <>
                        <Text>You're offline</Text>
                        <Text color="gray.300">
                          You can contact us when you reconnect.
                        </Text>
                      </>
                    )
                  }
                  aria-label="Contact us"
                >
                  <Box>
                    <MenuItem
                      isDisabled={!isConnected}
                      icon={
                        hasUnread ? (
                          <Box position="relative">
                            <Circle
                              position="absolute"
                              size={1.5}
                              bg="red.400"
                              top={0}
                              right={-1}
                            />
                            <FontAwesomeIcon
                              fixedWidth
                              icon={regular('bell')}
                              className="fa-beat-fade"
                            />
                          </Box>
                        ) : (
                          <FontAwesomeIcon
                            fixedWidth
                            icon={regular('message-smile')}
                          />
                        )
                      }
                      onClick={handleContactUsClick}
                    >
                      Contact us
                    </MenuItem>
                  </Box>
                </GammaTooltip>
              )}
              {!isIntercomEnabled && (
                <MenuItem
                  icon={
                    <FontAwesomeIcon
                      fixedWidth
                      icon={regular('message-smile')}
                    />
                  }
                  onClick={() => {
                    window.open(FEEDBACK_LINK, '_blank')
                  }}
                >
                  Submit feedback
                </MenuItem>
              )}
            </MenuGroup>
          </MenuList>
          <KeyboardShortcutsPopover
            isOpen={isKeyboardShortcutsPopoverOpen}
            onClose={onKeyboardShortcutsPopoverClose}
          />
        </Menu>
      </HelpWidgetWrapper>
    )
  }
)

DefaultHelpWidget.displayName = 'DefaultHelpWidget'
