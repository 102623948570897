import { StepType, useTour } from '@reactour/tour'
import { useCallback } from 'react'

import { TourNames } from '../constants'

export const useStartTour = (tourName: TourNames, steps: StepType[]) => {
  const { setIsOpen, setSteps, setMeta, setCurrentStep } = useTour()
  const startTour = useCallback(
    (startStep?: number) => {
      if (startStep !== undefined) {
        setCurrentStep(startStep)
      }
      setIsOpen(true)
      setMeta && setMeta(TourNames.DOC_EDITOR)
      setSteps && setSteps(steps)
    },
    [setCurrentStep, setIsOpen, setMeta, setSteps, steps]
  )

  return {
    startTour,
  }
}
